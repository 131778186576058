


























import { Component, Mixins, Prop } from 'vue-property-decorator'

import inputMixin from '../../mixins/input.mixin'

import inputWrapperElement from '../input-wrapper/input-wrapper.vue'
import iconElement from '../icon/icon.vue'

@Component({
  components: {
    inputWrapperElement,
    iconElement,
  },
})
export default class Checkbox extends Mixins(inputMixin) {
  /**
   * @model
   */
  @Prop({ required: true }) private readonly value!: boolean | unknown[]

  /**
   * Checkbox value
   */
  @Prop() private readonly label?: string

  get isChecked(): boolean {
    if (this.value instanceof Array) {
      return this.value.map((value) => JSON.stringify(value)).includes(JSON.stringify(this.label))
    }
    return !!this.value
  }

  set isChecked(isChecked: boolean) {
    if (this.disabled) {
      return
    }

    if (this.value instanceof Array) {
      const newValue = [...this.value]

      if (isChecked) {
        newValue.push(this.label)
      } else {
        const index = newValue
          .map((value) => JSON.stringify(value))
          .indexOf(JSON.stringify(this.label))
        newValue.splice(index, 1)
      }

      this.$emit('input', newValue)
    } else {
      this.$emit('input', isChecked)
    }
  }
}
