import i18n from '@/i18n'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '',
    redirect: `/${i18n.locale}`,
  },
  {
    path: '/:lang',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: '',
        component: () => import('@/views/index.vue'),
      },
      {
        path: 'about',
        component: () => import('@/views/about.vue'),
      },
      {
        path: 'faq',
        component: () => import('@/views/faq.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
