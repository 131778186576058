







































































import { Component, Mixins, Watch } from 'vue-property-decorator'

import Link from '@/types/link.d'

import buttonElement from '@/elements/components/button/button.vue'
import container from '@/components/page/page-container.vue'

import translationMixin from '@/mixins/translation.mixin'
import languageSwitcher from './page-language-switcher.vue'

@Component({
  components: {
    container,
    languageSwitcher,
    buttonElement,
  },
})
export default class extends Mixins(translationMixin) {
  get links(): Link[] {
    return [
      {
        label: this._getTranslation('header.professional'),
        to: `/${this.$i18n.locale}#professional`,
      },
      {
        label: this._getTranslation('header.client'),
        to: `/${this.$i18n.locale}#client`,
      },
      {
        label: this._getTranslation('header.subscription'),
        to: `/${this.$i18n.locale}#subscription`,
      },
      {
        label: this._getTranslation('header.about'),
        to: `/${this.$i18n.locale}/about`,
      },
      {
        label: this._getTranslation('header.faq'),
        to: `/${this.$i18n.locale}/faq`,
      },
      // {
      //   label: this._getTranslation('header.sign_up'),
      //   to: 'https://platform.sweevy.be/register',
      // },
    ]
  }

  @Watch('isMobileNavOpen')
  onIsMobileNavOpen(isOpen: boolean): void {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'
  }

  listeners(link: Link): Record<string, unknown> {
    if (link.to.includes('http')) {
      return {
        click: () => {
          this.isMobileNavOpen = false
        },
      }
    }

    return {
      'click.native': () => {
        this.isMobileNavOpen = false
      },
    }
  }

  goToLogin(): void {
    window.open('https://platform.sweevy.be/login', '_blank')
  }

  isMobileNavOpen = false
}
