













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Option',
})
export default class Option extends Vue {
  /**
   * Option value
   */
  @Prop({ required: true }) private readonly value!: Record<string, unknown>

  /**
   * Disable option
   */
  @Prop() private readonly disabled?: boolean

  selectedOption: unknown | null = null

  get isDisabled(): boolean {
    return !!(this.disabled || this.value?.disabled)
  }

  get isSelected(): boolean {
    return JSON.stringify(this.selectedOption) === JSON.stringify(this.value)
  }

  mounted(): void {
    this.$on('option-selected', this.handleOptionSelected)
  }

  beforeDestroy(): void {
    this.$off('option-selected', this.handleOptionSelected)
  }

  handleOptionSelected(option: unknown): void {
    this.selectedOption = option
  }

  selectOption(): void {
    if (!this.disabled) {
      this.$parent!.$emit('select', this.value)
    }
  }
}
