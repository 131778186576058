/* eslint-disable */
// @ts-nocheck
import Vue from 'vue'

Vue.directive('hover', {
  bind: function(el, binding, vnode) {
    let timeout: ReturnType<typeof setTimeout> | null = null

    el.mouseEnter = (e) => {
      if (timeout) {
        clearTimeout(timeout)
      }

      try {
        binding.value(true)
      } catch (e) {
        vnode.context[binding.expression] = true
      }
    }

    el.mouseLeave = (e) => {
      timeout = setTimeout(() => {
        try {
          binding.value(false)
        } catch (e) {
          vnode.context[binding.expression] = false
        }
      }, binding.arg || 0)
    }

    el.addEventListener('mouseenter', el.mouseEnter)
    el.addEventListener('mouseleave', el.mouseLeave)
  },
  unbind: function(el) {
    el.removeEventListener('mouseenter', el.mouseEnter)
    el.removeEventListener('mouseleave', el.mouseLeave)
  },
})
