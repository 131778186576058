































































import { Component, Vue } from 'vue-property-decorator'

import { switchElement } from '@/elements'

enum CookieCompliance {
  FUNCTIONAL = 1,
  ALL = 2,
}

@Component({
  components: {
    switchElement,
  },
})
export default class extends Vue {
  show = false

  showDetails = false

  categories = [
    {
      title: this.$t('cookie_banner.google_analytics'),
      enabled: false,
      cookies: [
        {
          name: '_ga',
          info: this.$t('cookie_banner._ga'),
        },
        {
          name: '_gat',
          info: this.$t('cookie_banner._gat'),
        },
        {
          name: '_gid',
          info: this.$t('cookie_banner._gid'),
        },
      ],
    },
  ]

  created(): void {
    const localStorageValue = +JSON.parse(localStorage.getItem('cookie_compliance') || '{}')

    if (Number.isNaN(localStorageValue)) {
      this.show = true
    } else if (localStorageValue === CookieCompliance.ALL) {
      this.categories[0].enabled = true
      this.$gtm.enable(true)
    }

    if (!Number.isNaN(localStorageValue)) {
      this.$root.$emit('create-calendly-widget')
    }

    this.$root.$on('showCookieBanner', () => {
      this.show = true
    })
  }

  acceptAll(): void {
    this.$gtm.enable(true)
  }

  savePreferences(all?: boolean): void {
    if (all || this.categories[0].enabled) {
      this.$gtm.enable(true)
      this.setCookieCompliance(CookieCompliance.ALL)
    } else {
      this.setCookieCompliance(CookieCompliance.FUNCTIONAL)
    }

    this.$root.$emit('create-calendly-widget')
  }

  setCookieCompliance(cookieCompliance: CookieCompliance): void {
    localStorage.setItem('cookie_compliance', JSON.stringify(cookieCompliance))
    this.show = false
  }
}
