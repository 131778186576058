








import { Component, Vue } from 'vue-property-decorator'

@Component
export default class extends Vue {
  created(): void {
    this.$on('select', this.handleOptionSelected)
  }

  handleOptionSelected(option: unknown): void {
    this.$emit('option-selected', option)
    this.$children.forEach(($child) => $child.$emit('option-selected', option))
  }
}
