





































import { Component, Mixins, Prop } from 'vue-property-decorator'

import inputMixin from '../../mixins/input.mixin'

@Component
export default class SwitchElement extends Mixins(inputMixin) {
  /**
   * @model
   */
  @Prop({ required: true }) private readonly value!: boolean

  /**
   * Active text
   */
  @Prop() private readonly activeText?: string

  /**
   * Inactive text
   */
  @Prop() private readonly inactiveText?: string

  switchWidth = 0

  get isSwitched(): boolean {
    return !!this.value
  }

  set isSwitched(isSwitched: boolean) {
    if (!this.disabled) {
      this.$emit('input', isSwitched)
    }
  }

  mounted(): void {
    this.switchWidth = (this.$refs.switch as HTMLElement).clientWidth
  }
}
