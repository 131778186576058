




/* eslint-disable import/no-dynamic-require */

import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Icon extends Vue {
  /**
   * Icon, without .svg extension
   * Should be located in @/assets/svg or any sub folder (svg/sub-folder/icon)
   */
  @Prop({ required: true }) private readonly icon!: string

  get svg(): SVGElement {
    // eslint-disable-next-line global-require
    return require(`!html-loader!@/assets/svg/${this.icon}.svg`)
  }

  get paths(): string {
    const svg = `${this.svg}`
    const svgTag: string = svg.substring(svg.indexOf('<svg'))
    const svgCloseTagIndex: number = svgTag.indexOf('>')
    const paths = svgTag
      .substring(svgCloseTagIndex + 1)
      .replace('</svg>', '')
      .trim()

    return paths
  }

  get viewBox(): string {
    const svg = `${this.svg}`
    const parts: string[] = svg.split('viewBox="')
    const end: number = parts[1].indexOf('"')

    return parts[1].slice(0, end)
  }
}
