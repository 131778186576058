























import { Component, Prop, Vue } from 'vue-property-decorator'

enum Position {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

enum Align {
  TOP = 'top',
  LEFT = 'left',
  CENTER = 'center',
  BOTTOM = 'bottom',
  RIGHT = 'right',
}

enum TooltipState {
  ENTERING,
  VISIBLE,
  LEAVING,
}

@Component
export default class extends Vue {
  /**
   * vue ref reference to an element or component
   */
  @Prop({ required: true }) private readonly reference!: string

  /**
   * Tooltip position
   * @values top, bottom, left, right
   */
  @Prop({ default: Position.BOTTOM }) private readonly position!: Position | string

  /**
   * Tooltip alignment
   * @values left, right, center, top, bottom
   */
  @Prop({ default: Align.CENTER }) private readonly align!: Align | string

  /**
   * Margin between tooltip and reference element
   */
  @Prop({ default: '0px' }) private readonly margin!: string

  /**
   * Content offset
   */
  @Prop({ default: '0px' }) private readonly offset!: string

  /**
   * Tooltip arrow alignment
   * @values left, right, center, top, bottom
   */
  @Prop({ default: Align.CENTER }) private readonly alignArrow!: Align | string

  /**
   * Tooltip arrow offset
   */
  @Prop({ default: '0px' }) private readonly arrowOffset!: string

  /**
   * Inherit width of reference element
   */
  @Prop() private readonly inheritReferenceWidth?: boolean

  /**
   * Show tooltip arrow
   */
  @Prop({ default: true }) private readonly arrow!: boolean

  /**
   * Tooltip z-index
   */
  @Prop({ default: 2 }) private readonly zIndex!: number

  /**
   * Sometimes you don't want to destroy the component without transitioning
   * when the parent is destroyed
   */
  @Prop() private readonly ignoreParentDestroyed?: boolean

  /**
   * Custom border radius
   */
  @Prop({ default: 'var(--border-radius)' }) private readonly borderRadius!: string

  parent: HTMLElement | null = null

  tooltip: HTMLElement | null = null

  content: HTMLElement | null = null

  parentBoundingRect: DOMRect = new DOMRect()

  tooltipBoundingRect: DOMRect = new DOMRect()

  parentComponent: Vue | null = null

  raf: ReturnType<typeof requestAnimationFrame> | null = null

  tooltipState: TooltipState = TooltipState.ENTERING

  pageYOffset: number = window.pageYOffset

  get computedZIndex(): number {
    if (this.tooltipState === TooltipState.ENTERING) {
      return this.zIndex + 2
    }
    if (this.tooltipState === TooltipState.VISIBLE) {
      return this.zIndex + 1
    }
    return this.zIndex
  }

  get tooltipStyle(): Record<string, string> {
    let translateX = '0'
    let translateY = '0'
    let width = 'auto'

    const isVertical = this.position === Position.TOP || this.position === Position.BOTTOM

    if (isVertical) {
      if (this.align === Align.LEFT) {
        translateX = `calc(${this.parentBoundingRect.left}px + ${this.offset})`
      } else if (this.align === Align.CENTER) {
        translateX = `calc(${this.parentBoundingRect.left +
          this.parentBoundingRect.width / 2 -
          this.tooltipBoundingRect.width / 2}px + ${this.offset})`
      } else if (this.align === Align.RIGHT) {
        translateX = `calc(${this.parentBoundingRect.left +
          this.parentBoundingRect.width -
          this.tooltipBoundingRect.width}px - ${this.offset})`
      }
      // horizontal
    } else if (this.align === Align.TOP) {
      translateY = `calc(${this.parentBoundingRect.top}px + ${this.offset})`
    } else if (this.align === Align.CENTER) {
      translateY = `calc(${this.parentBoundingRect.top +
        this.parentBoundingRect.height / 2 -
        this.tooltipBoundingRect.height / 2}px + ${this.offset})`
    } else if (this.align === Align.BOTTOM) {
      translateY = `calc(${this.parentBoundingRect.top +
        this.parentBoundingRect.height -
        this.tooltipBoundingRect.height}px - ${this.offset})`
    }

    if (this.position === Position.TOP) {
      translateY = `calc(${this.parentBoundingRect.top -
        this.tooltipBoundingRect.height +
        this.pageYOffset}px - ${this.margin})`
    } else if (this.position === Position.BOTTOM) {
      translateY = `calc(${this.parentBoundingRect.top +
        this.parentBoundingRect.height +
        this.pageYOffset}px + ${this.margin})`
    } else if (this.position === Position.LEFT) {
      translateX = `calc(${this.parentBoundingRect.left - this.tooltipBoundingRect.width}px - ${
        this.margin
      })`
    } else if (this.position === Position.RIGHT) {
      translateX = `calc(${this.parentBoundingRect.left + this.parentBoundingRect.width}px + ${
        this.margin
      })`
    }

    if (this.inheritReferenceWidth) {
      width = `${this.parentBoundingRect.width}px`
    }

    return {
      transform: `translate3d(${translateX}, ${translateY}, 0)`,
      width,
      '--z-index': `${this.computedZIndex}`,
      '--tooltip-border-radius': this.borderRadius,
    }
  }

  get tooltipArrowStyle(): Record<string, string> {
    let translateX = '0'
    let translateY = '0'
    let left = '0'
    let right = '0'
    let top = '0'
    let bottom = '0'

    const isVertical = this.position === Position.TOP || this.position === Position.BOTTOM

    if (isVertical) {
      if (this.alignArrow === Align.LEFT) {
        left = `calc(${this.arrowOffset})`
        right = 'auto'
      } else if (this.alignArrow === Align.CENTER) {
        left = '50%'
        right = 'auto'
        translateX = '-50%'
      } else if (this.alignArrow === Align.RIGHT) {
        left = 'auto'
        right = `calc(${this.arrowOffset})`
      }
    } else if (this.alignArrow === Align.TOP) {
      top = `calc(${this.arrowOffset})`
      bottom = 'auto'
    } else if (this.alignArrow === Align.CENTER) {
      top = `calc(50% + ${this.arrowOffset})`
      bottom = 'auto'
      translateY = '-50%'
    } else if (this.alignArrow === Align.BOTTOM) {
      top = 'auto'
      bottom = `calc(${this.arrowOffset})`
    }

    if (this.position === Position.TOP) {
      top = 'auto'
      translateY = '40%'
    } else if (this.position === Position.BOTTOM) {
      bottom = 'auto'
      translateY = '-50%'
    } else if (this.position === Position.LEFT) {
      left = 'auto'
      translateX = '40%'
    } else if (this.position === Position.RIGHT) {
      right = 'auto'
      translateX = '-40%'
    }

    return {
      top,
      bottom,
      left,
      right,
      transform: `translate3d(${translateX}, ${translateY}, 0) rotate(45deg)`,
    }
  }

  mounted(): void {
    // this.parent = this.reference ? this.getReferenceElement() : this.$el.parentElement
    this.parent = this.getReferenceElement()
    this.parentComponent = this.$parent

    if (!this.parent && this.reference) {
      console.warn('[Tooltip] A reference element was given, but could not be found')
      return
    }

    // this.$nextTick(() => {
    this.content = this.$refs.content as HTMLElement
    this.tooltip = this.$refs.tooltip as HTMLElement

    document.body.appendChild(this.$el as HTMLElement)

    this.raf = requestAnimationFrame(this.setBoundingRects)

    // fixes a weird bug where 'random' elements flash, what the fuck
    this.$nextTick(() => {
      this.raf = requestAnimationFrame(this.setBoundingRects)
    })
    // Fixes weird bug where it flashed top left corner
    // when e.g. another color-picker element is already present
    // setTimeout(() => {
    //   this.isReady = true
    // }, 0)
    // })

    window.addEventListener('scroll', this.handleScroll)
  }

  beforeDestroy(): void {
    if (this.raf) {
      cancelAnimationFrame(this.raf)
    }

    window.removeEventListener('scroll', this.handleScroll)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((this.parentComponent as any)._isBeingDestroyed) {
      this.$el.classList.add('tooltip-transition-leave-active', 'tooltip-transition-leave-to')

      this.$el.addEventListener(
        'transitionend',
        () => {
          document.body.removeChild(this.$el)
        },
        { once: true },
      )
      if (!this.ignoreParentDestroyed) {
        document.body.removeChild(this.$el)
      }
    }
  }

  handleAfterEnter(): void {
    this.tooltipState = TooltipState.VISIBLE
  }

  handleBeforeLeave(): void {
    this.tooltipState = TooltipState.LEAVING
  }

  handleScroll(): void {
    this.pageYOffset = window.pageYOffset
  }

  setBoundingRects(): void {
    if (!this.parent || !this.tooltip) {
      return
    }

    this.parentBoundingRect = this.parent.getBoundingClientRect()
    this.tooltipBoundingRect = this.tooltip.getBoundingClientRect()

    this.raf = requestAnimationFrame(this.setBoundingRects)
  }

  getReferenceElement(): HTMLElement {
    let parent = this.$parent
    let referenceElement = this.$parent!.$refs[this.reference]

    while (parent && referenceElement === undefined) {
      referenceElement = parent.$refs[this.reference]
      parent = parent.$parent
    }

    if (referenceElement instanceof Array) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;[referenceElement] = referenceElement
    }

    if ((referenceElement as Vue).$el) {
      referenceElement = (referenceElement as Vue).$el
    }

    return referenceElement as HTMLElement
  }
}
