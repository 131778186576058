
































import { Component, Vue, Prop } from 'vue-property-decorator'

import iconElement from '../icon/icon.vue'
import loaderElement from '../loader/loader.vue'

enum UIType {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

@Component({
  components: {
    iconElement,
    loaderElement,
  },
})
export default class Button extends Vue {
  /**
   * Native button type
   */
  @Prop({ default: 'button' }) private readonly type!: string

  /**
    Will convert the button to a router link
   */
  @Prop() private readonly to?: string

  /**
   * Button UI-type
   * @values success, warning, danger
   */
  @Prop() private readonly uiType?: string | UIType

  /**
   * Button icon (prefix)
   */
  @Prop() private readonly icon?: string

  /**
   * Button suffix icon
   */
  @Prop() private readonly suffixIcon?: string

  /**
   * Icon size
   */
  @Prop({ default: '0.9em' }) private readonly iconSize!: string

  /**
   * Button loader
   */
  @Prop() private readonly loading?: boolean

  /**
   * Secondary button UI
   */
  @Prop() private readonly secondary?: boolean

  /**
   * Rounded button UI
   */
  @Prop() private readonly rounded?: boolean

  /**
   * Round button UI
   */
  @Prop() private readonly round?: boolean

  /**
   * Plain button UI
   */
  @Prop() private readonly plain?: boolean

  /**
   * Custom button color
   */
  @Prop() private readonly color?: string

  /**
   * Dark text
   */
  @Prop() private readonly dark?: boolean

  /**
   * Disable button
   */
  @Prop() private readonly disabled?: boolean

  get buttonStyle(): Record<string, unknown> {
    let colors: Record<string, string> = {}

    if (this.secondary) {
      colors = {
        '--btn-color': this.buttonColor,
        '--btn-background-color': 'transparent',
        '--btn-border-color': this.buttonColor,
      }
    } else {
      colors = {
        '--btn-color': this.plain
          ? this.buttonColor
          : `${this.dark ? 'var(--text-dark)' : 'var(--text-light)'}`,
        '--btn-background-color': this.buttonColor,
        '--btn-border-color': this.buttonColor,
      }
    }

    return {
      ...colors,
      '--icon-size': this.iconSize,
    }
  }

  get loaderColor(): string {
    return this.secondary || this.plain ? this.buttonColor : 'var(--text-light)'
  }

  get buttonColor(): string {
    if (this.color) {
      return this.color
    }
    switch (this.uiType) {
      case UIType.SUCCESS:
        return 'var(--success)'
      case UIType.WARNING:
        return 'var(--warning)'
      case UIType.DANGER:
        return 'var(--error)'
      default:
        return 'var(--accent-primary)'
    }
  }
}
