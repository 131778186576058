import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class extends Vue {
  /**
   * Input title
   */
  @Prop() protected readonly title?: string

  /**
   * Input error
   */
  @Prop() protected readonly error?: string | boolean

  /**
   * Disable input
   */
  @Prop() protected readonly disabled?: boolean

  /**
   * Readonly
   */
  // @Prop() protected readonly readonly?: string
}
