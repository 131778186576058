

























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class InputWrapper extends Vue {
  /**
   * Title
   */
  @Prop() private readonly title?: string

  /**
   * Error
   */
  @Prop() private readonly error?: string | boolean

  /**
   * Disabled
   */
  @Prop() private readonly disabled?: boolean

  /**
   * Fit user content, or take full width
   */
  @Prop() private readonly fitContent!: boolean

  get style(): Record<string, unknown> {
    return {
      width: this.fitContent ? 'fit-content' : '100%',
    }
  }
}
