






















import {
  //
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator'

import iconElement from '../icon/icon.vue'

@Component({
  components: {
    iconElement,
  },
})
export default class Modal extends Vue {
  /**
   * Show modal
   */
  @Prop({ required: true }) private readonly show!: boolean

  isAlive = false

  @Watch('show', { immediate: true })
  onShowChange(show: boolean): void {
    this.isAlive = show
  }

  clickOutside(): void {
    this.close()
  }

  close(): void {
    this.isAlive = false
  }
}
