
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Loader extends Vue {
  /**
   * Loader size
   */
  @Prop({ default: '1.2em' }) private readonly size!: string

  /**
   * Loader color
   */
  @Prop({ default: 'var(--accent-primary)' }) private readonly color!: string

  get style(): Record<string, unknown> {
    return {
      '--size': this.size,
      '--stroke': this.color,
    }
  }
}
