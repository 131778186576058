









































import { Component, Prop, Vue } from 'vue-property-decorator'

import avatarElement from './avatar.vue'
import tooltipElement from '../tooltip/tooltip.vue'

interface User {
  name: string
  image?: string
}

@Component({
  components: {
    avatarElement,
    tooltipElement,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private readonly users!: User[]

  @Prop({ default: 4 }) private readonly max!: number

  @Prop({ default: true }) private readonly showUsersTooltip?: boolean

  @Prop({ default: '1.1em' }) private readonly overlap!: string

  showTooltip = false

  /**
   * @see <tooltip>
   */
  get tooltipProps(): Record<string, unknown> {
    const defaultProps = {
      margin: '0.25em',
      'border-radius': 'var(--border-radius-md)',
    }

    return {
      ...defaultProps,
      ...this.$attrs,
    }
  }

  get style(): Record<string, string> {
    return {
      '--avatar-overlap': `-${this.overlap}`,
    }
  }
}
