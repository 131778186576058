







import {
  //
  Component,
  Mixins,
  Prop,
  Watch,
} from 'vue-property-decorator'

import { unsavedChangesElement } from '@/elements'

import formMixin from '@/mixins/form.mixin'

import Form from '@/types/form.d'

import { AxiosError } from 'axios'

@Component({
  components: {
    unsavedChangesElement,
  },
})
export default class FormElement extends Mixins(formMixin) {
  /**
   * Form instance
   */
  @Prop({ required: true }) private readonly form!: Form

  /**
   * Whether form is for updating (PUT)
   */
  @Prop({ default: false }) private readonly isEdit!: boolean

  @Prop({ default: true }) private readonly showLoader!: boolean

  @Prop() private readonly ignoreUnsavedChanges?: boolean

  isLoading: boolean = this.isEdit

  isSubmitting = false

  hasUnsavedChanges = false

  @Watch('form', { deep: true })
  onFormChange(): void {
    if (!this.isLoading) {
      if (!this.ignoreUnsavedChanges) {
        this.hasUnsavedChanges = true
      }
    } else {
      // this is called when the form is initially set
      this.$nextTick(() => {
        this.isLoading = false
      })
    }
  }

  created(): void {
    this._setForm(this.form)
    this._addInputWatchers()

    if (this.isEdit) {
      if (this.showLoader) {
        // this.$setLoading(true)
      }

      this.$emit('setValues', (values: Record<string, unknown>) => {
        this._setFormValues(values)
        // this.$setLoading(false)
      })
    }
  }

  onFormValues(formValues: Record<string, unknown>): void {
    this._setFormValues(formValues)
  }

  submit(): void {
    if (!this._isValidForm()) {
      this._showErrors()
      return
    }

    this.isSubmitting = true

    if (this.showLoader) {
      // this.$setLoading(true)
    }

    this.$emit('submit', this._formValues, (error: AxiosError | undefined) => {
      this.hasUnsavedChanges = false
      this.isSubmitting = false
      // this.$setLoading(false)

      if (error) {
        this._setFormErrors(error)
      }
    })
  }
}
