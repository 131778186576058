


















































































import {
  //
  Component,
  Mixins,
  Prop,
  Watch,
} from 'vue-property-decorator'

import inputMixin from '../../mixins/input.mixin'

import inputWrapperElement from '../input-wrapper/input-wrapper.vue'
import iconElement from '../icon/icon.vue'
import loaderElement from '../loader/loader.vue'

@Component({
  inheritAttrs: false,
  components: {
    inputWrapperElement,
    iconElement,
    loaderElement,
  },
})
export default class Input extends Mixins(inputMixin) {
  /**
   * @model
   */
  @Prop({ required: true }) private readonly value!: string | number

  /**
   * Input type (native)
   * @values text, password, number, textarea
   */
  @Prop({ default: 'text' }) private readonly type!: string

  /**
   * Input icon (prefix)
   */
  @Prop() private readonly icon?: string

  /**
   * Input suffix icon
   */
  @Prop() private readonly suffixIcon?: string

  /**
   * Input loader
   */
  @Prop() private readonly loading?: boolean

  /**
   * Readonly
   */
  @Prop() protected readonly readonly?: boolean

  /**
   * Show input border
   */
  @Prop({ default: true }) private readonly border!: boolean

  /**
   * Input height (textarea)
   */
  @Prop({ default: 'auto' }) private readonly height!: string

  /**
   * Resize (textarea)
   * @values none, horizontal, vertical, both
   */
  @Prop({ default: 'none' }) private readonly resize!: string

  actualValue: string | number = this.value

  showPassword = false

  isFocused = false

  get componentType(): string {
    return this.type === 'textarea' ? 'textarea' : 'input'
  }

  get listeners(): Record<string, unknown> {
    return {
      ...this.$listeners,
      focus: (e: { target: HTMLInputElement | HTMLTextAreaElement }) => {
        this.$emit('focus', e)
        this.isFocused = true
      },
      blur: (e: { target: HTMLInputElement | HTMLTextAreaElement }) => {
        this.$emit('blur', e)
        this.isFocused = false
      },
      input: (e: { target: HTMLInputElement | HTMLTextAreaElement }) => {
        this.$emit('input', e.target?.value)
      },
      mouseup: () => {
        // without this, it will register double clicks
      },
    }
  }

  get inputType(): string {
    if (this.type === 'password') {
      return this.showPassword ? 'text' : 'password'
    }
    return this.type
  }

  get inputStyle(): Record<string, string> {
    return {
      resize: this.resize,
      height: this.height,
    }
  }

  get borderStyle(): Record<string, string> {
    return {
      'border-width': this.border ? '1px' : '0',
    }
  }

  get computedSuffixIcon(): string | undefined {
    if (this.type === 'password') {
      return this.showPassword ? 'elements/eye-hide' : 'elements/eye-view'
    }
    return this.suffixIcon
  }

  @Watch('value')
  onValueChange(value: string): void {
    this.actualValue = value
  }

  @Watch('actualValue')
  onActualValueChange(value: string): void {
    this.$emit('input', value)
  }

  handleSuffixIconClick(): void {
    this.showPassword = !this.showPassword
  }
}
