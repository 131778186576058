









import { Component, Vue, Watch } from 'vue-property-decorator'

import pageHeader from '@/components/page/page-header.vue'
import pageFooter from '@/components/page/page-footer.vue'
import cookieBanner from '@/components/cookie-banner/cookie-banner.vue'

@Component({
  components: {
    pageHeader,
    pageFooter,
    cookieBanner,
  },
})
export default class extends Vue {
  hasCreatedWidget = false

  @Watch('$route')
  onRouteChange(): void {
    this.$nextTick(() => {
      window.scrollTo(0, 0)
    })
  }

  created(): void {
    this.$root.$on('create-calendly-widget', this.createCalendlyWidget)
  }

  createCalendlyWidget(): void {
    if (this.hasCreatedWidget) {
      return
    }

    this.hasCreatedWidget = true

    const calendlyCss = document.createElement('link')
    calendlyCss.href = 'https://assets.calendly.com/assets/external/widget.css'
    calendlyCss.setAttribute('rel', 'stylesheet')

    document.head.appendChild(calendlyCss)

    const widgetScript = document.createElement('script')
    widgetScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    // widgetScript.setAttribute('async', '')
    document.body.appendChild(widgetScript)

    setTimeout(() => {
      const initScript = document.createElement('script')
      initScript.innerHTML = `Calendly.initBadgeWidget({
        url: 'https://calendly.com/sweevy/demo-sweevy', text: 'Vragen? Boek een live demo met Sweevy', color: '#ED0206', textColor: '#F4F9FF', branding: true,
      })`

      document.body.appendChild(initScript)
    }, 500)
  }
}
