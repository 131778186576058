import { Component, Vue } from 'vue-property-decorator'

@Component
export default class extends Vue {
  protected _getTranslation(translation: string): string {
    return this.getProperty(this.$i18n.messages[this.$i18n.locale], translation)
  }

  getProperty(json: Record<string, unknown>, path: string): string {
    const tokens = path.split('.')

    let obj = json

    for (let i = 0; i < tokens.length; i += 1) {
      obj = obj[tokens[i]] as Record<string, unknown>
    }

    return (obj as unknown) as string
  }
}
