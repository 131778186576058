












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop() private readonly image?: string

  @Prop() private readonly name?: string

  @Prop() private readonly rounded?: boolean

  @Prop({ default: 'var(--gray-tertiary)' }) private readonly backgroundColor!: string

  @Prop({ default: 'var(--background-secondary)' }) private readonly borderColor!: string

  colors: string[] = [
    '#00BFA5',
    '#6200EA',
    '#FFD600',
    '#64DD18',
    '#AA00FF',
    '#C51162',
    '#2A62FF',
    '#00C853',
    '#FFAB00',
    '#0091EA',
    '#3E2723',
    '#3050FE',
    '#D50000',
    '#4447e0',
    '#263238',
    '#DD2C00',
    '#212121',
    '#FF6D00',
    '#00B8D4',
    '#00BFA5',
    '#6200EA',
    '#FFD600',
    '#AA00FF',
    '#64DD18',
    '#C51162',
    '#2A62FF',
  ]

  get style(): Record<string, string> {
    return {
      '--avatar-border-radius': this.rounded ? 'var(--border-radius-md)' : '50%',
      '--avatar-background-color': this.image ? '' : this.avatarColor,
      '--avatar-border-color': this.borderColor,
    }
  }

  get avatarColor(): string {
    if (this.name) {
      return this.colors[
        'abcdefghijklmnopqrstuvwxyz'.split('').indexOf(this.name[0].toLocaleLowerCase())
      ]
    }

    return this.backgroundColor
  }

  get initials(): string | null {
    if (!this.name) {
      return null
    }

    return this.name
      .split(' ')
      .map((name) => name[0])
      .join('')
  }
}
