




















































































import {
  //
  Component,
  Mixins,
  Prop,
  Watch,
} from 'vue-property-decorator'

import inputMixin from '../../mixins/input.mixin'

import inputElement from '../input/input.vue'
import tooltipElement from '../tooltip/tooltip.vue'
import optionElement from './option.vue'
import optionWrapperElement from './option-wrapper.vue'
import iconElement from '../icon/icon.vue'

enum Position {
  TOP,
  BOTTOM,
}

@Component({
  name: 'Select',
  components: {
    inputElement,
    tooltipElement,
    optionElement,
    optionWrapperElement,
    iconElement,
  },
})
export default class Select extends Mixins(inputMixin) {
  /**
   * @model
   */
  @Prop({ required: true }) private readonly value!: Record<string, unknown> | string

  /**
   * Options
   */
  @Prop() private readonly options?: Record<string, unknown>[] | string[]

  /**
   * Property used to display value if object array is used
   */
  @Prop() private readonly label?: string

  /**
   * Method which will be called before emitting input value
   */
  @Prop() private readonly beforeInput?: (
    value: Record<string, unknown> | string,
  ) => Record<string, unknown> | string

  /**
   * Don't hide dropdown when option is selected
   */
  @Prop({ default: false }) private readonly dontCloseOnSelect!: boolean

  selectedOption: Record<string, unknown> | string | null = null

  showDropdown = false

  actualValue: Record<string, unknown> | string | null = this.value

  isQuery = false

  position: Position = Position.BOTTOM

  /**
   * @see <input>
   */
  get inputProps(): Record<string, unknown> {
    const defaultProps = {
      readonly: true,
    }

    return {
      ...defaultProps,
      ...this.$attrs,
    }
  }

  /**
   * @see <tooltip>
   */
  get tooltipProps(): Record<string, unknown> {
    const defaultProps = {
      'align-arrow': 'right',
      'arrow-offset': '0.55em',
      margin: '0.5em',
    }

    return {
      ...defaultProps,
      ...this.$attrs,
    }
  }

  get listeners(): Record<string, unknown> {
    return {
      ...this.$listeners,
      mouseup: () => {
        this.handleInputClick()
      },
      keyup: (e: KeyboardEvent) => {
        this.handleKeyUp(e)
        this.$emit('keyup', e)
      },
      input: () => {
        // without this, search will not work properly
        // because it emits an input event
      },
    }
  }

  get computedActualValue(): string | null {
    if (!this.actualValue) {
      return null
    }
    if (typeof this.actualValue === 'string') {
      return this.actualValue
    }
    if (this.label && this.actualValue[this.label]) {
      return this.actualValue[this.label] as string
    }
    return null
  }

  get positionAsString(): string {
    if (this.position === Position.TOP) {
      return 'top'
    }
    return 'bottom'
  }

  @Watch('value')
  onValueChange(value: Record<string, unknown> | string | null): void {
    this.actualValue = value

    if (this.showDropdown && !this.dontCloseOnSelect) {
      this.showDropdown = false

      const activeElement = document.activeElement as HTMLElement

      if (activeElement) {
        activeElement.blur()
      }
    }
  }

  @Watch('showDropdown')
  onShowDropdownchange(show: boolean): void {
    if (show) {
      this.$nextTick().then(() => {
        const dropdown = this.$refs.dropdown as HTMLElement
        const select = this.$refs.select as HTMLElement

        if (
          window.innerHeight -
            select.getBoundingClientRect().top -
            select.getBoundingClientRect().height -
            dropdown.getBoundingClientRect().height -
            10 <=
          0
        ) {
          this.position = Position.TOP
        } else {
          this.position = Position.BOTTOM
        }
      })
    }
  }

  handleInputClick(): void {
    this.showDropdown = this.disabled || this.inputProps.loading ? false : !this.showDropdown
  }

  select(option: Record<string, unknown> | string): void {
    this.isQuery = false
    this.showDropdown = this.dontCloseOnSelect
    this.actualValue = option

    if (this.beforeInput) {
      this.selectedOption = this.beforeInput(option)
    } else {
      this.selectedOption = option
    }

    this.$emit('input', this.selectedOption)
  }

  handleKeyUp(e: KeyboardEvent): void {
    const value = (e.target as HTMLInputElement).value as string
    this.actualValue = value
    this.$emit('query', value)
  }
}
