






































import { Component, Vue } from 'vue-property-decorator'

import modalElement from '../modal/modal.vue'
import buttonElement from '../button/button.vue'

import Dialog from './dialog-type.d'

@Component({
  components: {
    modalElement,
    buttonElement,
  },
})
export default class DialogElement extends Vue {
  dialog: Dialog | null = null

  showDialog = false

  isDisabled = false

  mounted(): void {
    Vue.prototype.$dialog = (dialog: Dialog) => {
      this.isDisabled = false
      this.dialog = dialog

      this.$nextTick(() => {
        this.showDialog = true
      })
    }
  }

  handlePrimaryActionClick(): void {
    this.isDisabled = true

    // eslint-disable-next-line no-unused-expressions
    this.dialog?.primaryAction?.click(() => {
      this.showDialog = false
    })
  }

  handleSecondaryActionClick(): void {
    this.isDisabled = true

    // eslint-disable-next-line no-unused-expressions
    this.dialog?.secondaryAction?.click(() => {
      this.showDialog = false
    })
  }

  close(): void {
    this.showDialog = false
  }
}
