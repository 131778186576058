

























import { Component, Mixins, Watch } from 'vue-property-decorator'

import { inputElement, modalElement } from '@/elements'

import formElement from '@/components/elements/form-element.vue'

import Form from '@/types/form.d'
import validationMixin from '@/mixins/validation.mixin'
import formMixin from '@/mixins/form.mixin'

@Component({
  components: {
    formElement,
    inputElement,
    modalElement,
  },
})
export default class extends Mixins(formMixin, validationMixin) {
  form: Form = {
    email: {
      value: null,
      error: null,
      validate: (value) => {
        if (!value) {
          return this.requiredError
        }

        if (this._validateEmail(value)) {
          return (this.$i18n.messages[this.$i18n.locale].errors as Record<string, string>)
            .invalid_email as string
        }

        return null
      },
    },
  }

  success = false

  get requiredError(): string {
    return (this.$i18n.messages[this.$i18n.locale].errors as Record<string, string>)
      .required as string
  }

  created(): void {
    this._setForm(this.form)
    this._addInputWatchers()
  }

  require(value: unknown | null): string | null {
    if (!value) {
      return this.requiredError
    }
    return null
  }

  onSubmit(formValues: () => Record<string, unknown>, done: () => void): void {
    this.$axios
      .post(process.env.VUE_APP_NEWSLETTER_URL, formValues())
      .then(() => {
        this.success = true
        this.form.email.value = null

        this.$nextTick(() => {
          this.form.email.error = null
        })
      })
      .finally(() => {
        done()
      })
  }
}
