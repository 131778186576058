import { Component, Vue } from 'vue-property-decorator'

interface ValidationParams {
  required?: boolean
  min?: number
  max?: number
  match?: {
    name: string
    value: string
  }
}

interface NumberValidationparams extends ValidationParams {
  canBeZero?: boolean
}

@Component
export default class extends Vue {
  private REQUIRED = 'Dit veld is verplicht'

  protected _validateEmail(email: string | null): string | null {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

    if (!email || !email.length) {
      return this.REQUIRED
    }

    if (!re.test(email)) {
      return 'Ongeldig e-mailadres'
    }

    return null
  }

  protected _validateUrl(value: string | null, params?: ValidationParams): string | null {
    if (params?.required && (!value || !value.length)) {
      return this.REQUIRED
    }

    if (!value || !value.length) {
      return null
    }

    const regex = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/

    if (!regex.test(value)) {
      return 'Ongeldige url'
    }

    if (!value.includes('https')) {
      return 'Url moet https bevatten'
    }

    return null
  }

  protected _validateString(value: string | null, params?: ValidationParams): string | null {
    if (params?.required && (!value || !value.length)) {
      return this.REQUIRED
    }

    if (params?.min && value && value.length < params.min) {
      return `Minimum ${params.min} tekens`
    }

    if (params?.max && value && value.length > params.max) {
      return `Maximum ${params.min} tekens`
    }

    if (params?.match && params.match.value !== value) {
      return `Komt niet overeen met ${params.match.name}`
    }

    return null
  }

  protected _validateNumber(value: number | null, params?: NumberValidationparams): string | null {
    if (params?.required && (!value || (!params.canBeZero && value === 0))) {
      return this.REQUIRED
    }

    if (params?.min && value && value < params.min) {
      return `Minimum ${params.min}`
    }

    if (params?.max && value && value > params.max) {
      return `Maximum ${params.min}`
    }

    return null
  }

  protected _required(value: unknown): string | null {
    if (!value) {
      return this.REQUIRED
    }

    return null
  }
}
