import { iconElement, buttonElement } from '@/elements'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { VueMaskDirective } from 'v-mask'

import '@/elements/directives/directives'
import clickOutsideDirective from '@/elements/directives/click-outside'
import Component from 'vue-class-component'
import VueGtm from '@gtm-support/vue2-gtm'

import axios, { AxiosStatic } from 'axios'

import Vue from 'vue'
import Meta from 'vue-meta'
import App from './App.vue'
import router from './router'
import i18n from './i18n'

Vue.use(Meta)
Vue.directive('mask', VueMaskDirective)

Vue.config.productionTip = false

Component.registerHooks(['metaInfo'])

Vue.component('icon-element', iconElement)
Vue.component('button-element', buttonElement)
Vue.directive('click-outside', clickOutsideDirective)

Vue.use(VueGtm, {
  id: 'G-PDWVFY2YN1',
  vueRouter: router,
  enabled: false,
  debug: true,
})

router.beforeEach((to, from, next) => {
  let language = to.params.lang

  i18n.locale = language

  const isLocale = i18n.availableLocales.indexOf(language) !== -1

  if (!isLocale) {
    // router.replace(`/${i18n.fallbackLocale}/about`)
    language = i18n.fallbackLocale as string

    router.push(`/${i18n.fallbackLocale}${to.path}`)
  }

  next()
})

Vue.prototype.$axios = axios

declare module 'vue/types/vue' {
  interface Vue {
    $axios: AxiosStatic
  }
}

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
