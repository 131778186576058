var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.to ? 'router-link' : 'button',_vm._g(_vm._b({ref:"button",tag:"component",staticClass:"button-el",class:[
    {
      'state-loading': _vm.loading,
      'state-plain': _vm.plain,
      'state-round': _vm.round,
      'state-rounded': _vm.rounded,
      'state-disabled': _vm.disabled,
    } ],style:(_vm.buttonStyle),attrs:{"type":_vm.type,"to":_vm.to,"disabled":_vm.disabled || _vm.loading}},'component',_vm.$attrs,false),_vm.$listeners),[_c('span',{staticClass:"button-el__content"},[(_vm.icon)?_c('icon-element',{staticClass:"button-el__content__icon",attrs:{"icon":_vm.icon}}):_vm._e(),(_vm.$slots.default)?_c('span',[_vm._t("default")],2):_vm._e(),(_vm.loading)?_c('loader-element',{staticClass:"button-el__content__loader",attrs:{"color":_vm.loaderColor}}):_vm._e(),(_vm.suffixIcon)?_c('icon-element',{staticClass:"button-el__content__suffix-icon",attrs:{"icon":_vm.suffixIcon}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }