






import { Component, Vue, Watch } from 'vue-property-decorator'

import { selectElement } from '@/elements'

@Component({
  components: {
    selectElement,
  },
})
export default class extends Vue {
  locale: string = this.$i18n.locale

  get availableLocales(): string[] {
    return this.$i18n.availableLocales
  }

  @Watch('locale')
  onLocaleChange(): void {
    this.$i18n.locale = this.locale

    this.$router.push({
      params: {
        lang: this.locale,
      },
    })
  }
}
