import { Component, Vue } from 'vue-property-decorator'

import Form from '@/types/form.d'
import { AxiosError } from 'axios'

interface FormValuesOptions {
  exclude: string[]
}

interface FormValues {
  [key: string]: unknown
}

@Component
export default class extends Vue {
  private _form!: Form

  private initialState: Form = {}

  /**
   * Set form property
   * @param form
   */
  protected _setForm(form: Form): void {
    this._form = form

    Object.entries(this._form).forEach(([input, formProperty]) => {
      this.$set(this.initialState, input, { ...formProperty })
    })

    this.validateForm()
  }

  /**
   * Validate form
   */
  private validateForm(): void {
    Object.entries(this._form).forEach(([input, formProperty]) => {
      if ('validate' in formProperty && !('error' in formProperty)) {
        console.warn(`Missing 'error' property in form property ${input}`)
      }
    })
  }

  protected _resetForm(): void {
    Object.entries(this.initialState).forEach(([key, value]) => {
      this._form[key] = value
    })
  }

  /**
   * Set form values
   * @param values
   */
  protected _setFormValues(values: Record<string, unknown>): void {
    Object.keys(this._form).forEach((input) => {
      const formProperty = this._form[input]

      if (formProperty.set !== undefined) {
        formProperty.value = formProperty.set(values[input], values)
      } else if (formProperty.value !== undefined && values[input] !== undefined) {
        formProperty.value = values[input]
      }
    })
  }

  /**
   * Set form errors from an axios error
   * @param axiosError
   */
  protected _setFormErrors(axiosError: AxiosError): void {
    if (axiosError.response) {
      Object.keys(axiosError.response.data.errors).forEach((input) => {
        this._form[input].error = axiosError.response?.data.errors[input][0]
      })
    }
  }

  /**
   * Get a value of form properties which have either a getter or a value
   * @param options
   * @returns
   */
  protected _formValues(options: FormValuesOptions = { exclude: [] }): Record<string, unknown> {
    const data: FormValues = {}

    Object.keys(this._form).forEach((input) => {
      const formProperty = this._form[input]

      if (options.exclude.indexOf(input) === -1) {
        // get
        if (formProperty.get !== undefined) {
          data[input] = formProperty.get(this._form[input].value)
          // value
        } else if (formProperty.value !== undefined) {
          data[input] = formProperty.value
        }
      }
    })

    return data
  }

  /**
   * Get a value of form properties as a FormData object
   * @param options
   * @returns
   */
  protected _formData(options?: FormValuesOptions): FormData {
    const formData = new FormData()
    const formValues = this._formValues(options)

    Object.keys(formValues).forEach((property) => {
      const value = formValues[property]

      // also counts for arrays
      if (typeof value === 'object') {
        formData.set(property, JSON.stringify(value))
      } else {
        formData.set(property, value as string)
      }
    })

    return formData
  }

  /**
   * Validate form properties. Errors will be shown after value is changed from its initial state
   * @returns
   */
  protected _isValidForm(): boolean {
    let hasErrors = false

    // eslint-disable-next-line consistent-return
    Object.values(this._form).forEach((formProperty) => {
      if (formProperty.validate !== undefined) {
        const validationResponse = formProperty.validate(formProperty.value)

        if (
          typeof validationResponse === 'string' ||
          (typeof validationResponse === 'boolean' && !validationResponse)
        ) {
          hasErrors = true
        }
      }
    })

    return !hasErrors
  }

  /**
   * Force show form errors. Can be used to display errors before the values have been
   * changed from their initial state
   */
  protected _showErrors(): void {
    Object.entries(this._form).forEach(([input, formProperty]) => {
      if (formProperty.value !== undefined && formProperty.validate !== undefined) {
        this._form[input].error = formProperty.validate(formProperty.value)
      }
    })
  }

  /**
   * Watch form properties which have a value and validation rule and show the error
   * if invalid (requires a change from its initial state)
   */
  protected _addInputWatchers(): void {
    Object.entries(this._form).forEach(([input, formProperty]) => {
      if (formProperty.value !== undefined && formProperty.validate !== undefined) {
        this.$watch(
          () => formProperty.value,
          () => {
            if (formProperty.validate !== undefined) {
              this._form[input].error = formProperty.validate(formProperty.value)
            }
          },
          { deep: true },
        )
      }
    })
  }
}
